import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-grid--totals" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "count-label" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "chats-list-item-header" }
const _hoisted_6 = { class: "chats-item-header-left" }
const _hoisted_7 = {
  key: 0,
  class: "chat-item-header-right"
}
const _hoisted_8 = {
  key: 1,
  class: "icon-chevron_big_right"
}
const _hoisted_9 = { class: "chat-list-item-footer" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "chat-list-item-date" }
const _hoisted_13 = { class: "chat-list-item-user" }
const _hoisted_14 = {
  key: 0,
  class: "see-all-container"
}

import dateFormat from '@/helpers/DateFormat.helper';
import Chat from '@/models/Chat';
import router from '@/router';
import { chatService } from '@/services/ChatService';
import { ServiceError } from '@/services/util/ServiceError';
import { onMounted, ref, watch, computed } from 'vue';
import { MessageType } from '@/models/Message';
import EmptyState from '../EmptyState.vue';
import { useProfileStore } from '@/store/profile.module';


export default /*@__PURE__*/_defineComponent({
  __name: 'LastChats',
  props: {
  professionalId: {
    type: String,
  },
},
  setup(__props) {

const storeProfile = useProfileStore();
const isAdminUser = computed(() => storeProfile.isAdmin);
const chats = ref<Chat[]>([]);

const newMessageIconAlert = window.location.origin + '/assets/images/new-message-alert.svg';

const props = __props;

watch(
  () => props.professionalId,
  () => loadChats(),
);

onMounted(async () => {
  await loadChats();
});

async function loadChats() {
  const result = await chatService.findUnreadChats(props.professionalId);
  if (!(result instanceof ServiceError)) {
    chats.value = result;
  }
}

const selectChat = (chat: Chat) => {
  if (!isAdminUser.value) {
    router.push('/chats?chatId=' + chat.id);
  }
};

const onSeeAll = () => {
  router.push('/chats');
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (chats.value && chats.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('dashboard.lastChats')) + " ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(`(${chats.value.length})`), 1)
          ]),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chats.value, (chat, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass(isAdminUser.value ? 'chats-list-item-admin' : 'chats-list-item'),
                onClick: ($event: any) => (selectChat(chat))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h1", null, _toDisplayString((chat.oppositeUser.name ? chat.oppositeUser.name : '') +
                  ' ' +
                  (chat.oppositeUser.surname ? chat.oppositeUser.surname : '')), 1)
                  ]),
                  (chat.lastMessageDate)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (chat.messagesIdUnreadByUserId && chat.messagesIdUnreadByUserId.length > 0)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: newMessageIconAlert,
                              alt: ""
                            }))
                          : _createCommentVNode("", true),
                        (!isAdminUser.value)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_8))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  (chat.lastMessageType === _unref(MessageType).IMAGE)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                        _cache[0] || (_cache[0] = _createElementVNode("i", {
                          class: "icon-chat-sent",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(chat.userId === chat.lastUserIdSendMessage ? _ctx.$t('chat.image-sent') : _ctx.$t('chat.image-received')), 1)
                      ]))
                    : (
                (chat.lastMessageType === _unref(MessageType).TEXT || chat.lastMessageType == undefined) &&
                chat.lastMessageValue
              )
                      ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(chat.lastMessageValue), 1))
                      : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", null, _toDisplayString(_unref(dateFormat).formatDateOnlyHour24h(_unref(dateFormat).utcToUserTimezone(chat.lastMessageDate.toString()))), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "icon-user_voice" }, null, -1)),
                  _createElementVNode("p", null, _toDisplayString(chat.user.name + ' ' + chat.user.surname), 1)
                ])
              ], 10, _hoisted_4))
            }), 128))
          ]),
          (!isAdminUser.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_Button, {
                  class: "p-button-link",
                  label: _ctx.$t('dashboard.see-all'),
                  onClick: onSeeAll
                }, null, 8, ["label"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(EmptyState, {
          key: 1,
          heading: _ctx.$t('dashboard.empty-last-chats-title'),
          text: _ctx.$t('dashboard.empty-last-chats-message'),
          secondary: false,
          icon: "icon-chat"
        }, null, 8, ["heading", "text"]))
  ]))
}
}

})