import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid--analytics" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "chart-title" }
const _hoisted_4 = { class: "year-selector" }

import i18n from '@/i18n';
import { Chart as ChartResponse } from '@/models/Statistics';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import Chart from 'chart.js/auto';
import moment from 'moment';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'NutritionalPlansChart',
  props: {
  professionalId: {
    type: String,
  },
},
  setup(__props) {

const props = __props;

watch(
  () => props.professionalId,
  () => reDrawChart(),
);

const { t } = useI18n();

const chart = ref<ChartResponse>();
let chartObject: Chart | null = null;

const chartOptions = {
  plugins: {
    legend: {
      display: true,
      position: 'top',
      align: 'center',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 20,
        generateLabels: function (chart: any) {
          const datasets = chart.data.datasets;
          return datasets.map((dataset: any, i: any) => ({
            text: dataset.label,
            fillStyle: dataset.backgroundColor,
            hidden: !chart.isDatasetVisible(i),
            lineCap: 'round',
            lineDash: [],
            lineDashOffset: 0,
            lineJoin: 'round',
            strokeStyle: dataset.backgroundColor,
            pointStyle: 'circle',
            datasetIndex: i,
          }));
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 10,
      ticks: {
        precision: 0,
      },
    },
  },
};

const year = ref(moment().year());

const getLastFiveYears = () => {
  const currentYear = moment().year();

  const years: { label: string; value: number }[] = [];

  for (let i = 0; i < 5; i++) {
    years.push({ label: currentYear - i + '', value: currentYear - i });
  }

  return years;
};

const yearOptions = ref(getLastFiveYears());

onMounted(async () => {
  await loadResume();
  drawChart();
});

onUnmounted(() => {
  if (chartObject) {
    chartObject.destroy();
  }
});

async function loadResume() {
  const result = await patientService.getPatientAnalyticsNutritional(year.value, props.professionalId);
  if (!(result instanceof ServiceError)) {
    chart.value = result;
  }
}

const drawChart = () => {
  const container = document.getElementById('nutritional-plan');

  if (!container) {
    return;
  }
  const canvas = container as HTMLCanvasElement;
  if (!canvas) {
    return;
  }

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: t('dashboard.nutritionalPlan'),
        data: getNutritionalPlanValues(),
        backgroundColor: '#63ABFD', // no template color
        order: 1,
      },
    ],
  };

  chartObject = new Chart(canvas, {
    type: 'bar',
    data: data,
    options: chartOptions as any,
  });
};

const getLabels = () => {
  moment.locale(i18n.global.locale.value);
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(moment().month(i).format('MMM'));
  }
  return months;
};

const getNutritionalPlanValues = () => {
  const nutritionalPlanSeries = chart.value?.series.filter((serie) => serie.name === 'NutritionalPlan');

  return nutritionalPlanSeries ? nutritionalPlanSeries[0].data : [];
};

const onYearChange = async () => {
  reDrawChart();
};

const reDrawChart = async () => {
  if (chartObject) {
    chartObject.destroy();
  }
  await loadResume();
  drawChart();
};

return (_ctx: any,_cache: any) => {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('dashboard.nutritionalPlanCreated')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Dropdown, {
          id: "year",
          modelValue: year.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((year).value = $event)),
          options: yearOptions.value,
          "option-label": "label",
          "option-value": "value",
          onChange: onYearChange
        }, null, 8, ["modelValue", "options"])
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-chart__container" }, [
      _createElementVNode("canvas", { id: "nutritional-plan" })
    ], -1))
  ]))
}
}

})