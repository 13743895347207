
import GetRequestError from '@/components/common/GetRequestError.vue';
import LastChats from '@/components/dashboard/LastChats.vue';
import NewPatientsGreeting from '@/components/dashboard/NewPatientsGreeting.vue';
import NutritionalPlansChart from '@/components/dashboard/NutritionalPlansChart.vue';
import PatientAnalyticsChart from '@/components/dashboard/PatientAnalyticsChart.vue';
import TotalsSummary from '@/components/dashboard/TotalsSummary.vue';
import EmptyState from '@/components/EmptyState.vue';
import AppHeading from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { PatientDto } from '@/models/Patient';
import { UserRole, UserTableDto } from '@/models/User';
import router from '@/router';
import { patientService } from '@/services/PatientService';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    AppHeading,
    LoadingComponent,
    EmptyState,
    GetRequestError,
    TotalsSummary,
    LastChats,
    PatientAnalyticsChart,
    NewPatientsGreeting,
    NutritionalPlansChart,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const storeProfile = useProfileStore();
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const patientCreateModalRef = ref();
    const patients: Ref<PatientDto[] | undefined> = ref();
    const isLoadingPatients = ref(true);
    const isPatientsListEmpty = computed(() => patients.value !== undefined && patients.value.length === 0);
    const isPatientsRequestErrorful = ref(false);
    const isEditModal = ref(false);
    const professionalId = ref();
    const specialists: Ref<UserTableDto[] | undefined> = ref();
    const selectedPatientName = computed(
      () =>
        specialists.value?.find((specialist) => specialist.professionalId == professionalId.value)?.name ??
        t('dashboard.global'),
    );

    const loadPatientList = async () => {
      isLoadingPatients.value = true;
      const result = await patientService.findAll();
      if (!(result instanceof ServiceError)) {
        patients.value = result;
      } else if (result.status === 500) {
        isPatientsRequestErrorful.value = true;
      }
      isLoadingPatients.value = false;
    };

    async function loadSpecialists() {
      const result = await userService.findAll([UserRole.SPECIALIST]);
      if (!(result instanceof ServiceError)) {
        specialists.value = result;

        specialists.value.unshift({ name: 'Global', professionalId: undefined } as any);
      }
    }

    onMounted(async () => {
      await loadPatientList();
      await loadSpecialists();
    });

    return {
      route,
      router,
      patientCreateModalRef,
      patients,
      isLoadingPatients,
      isPatientsListEmpty,
      isPatientsRequestErrorful,
      professionalId,
      specialists,
      selectedPatientName,
      isAdminUser,
      loadPatientList,
      isEditModal,
    };
  },
});
