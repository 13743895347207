import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = { class: "icon-circle" }
const _hoisted_3 = { class: "totals-title" }
const _hoisted_4 = { class: "totals-footer" }
const _hoisted_5 = { class: "total-number" }

import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TotalsCard',
  props: {
  title: {
    type: String,
    required: true,
  },
  total: {
    type: Number,
  },
  icon: {
    type: String,
  },
},
  emits: ['onCardClick'],
  setup(__props, { emit: __emit }) {



const emits = __emit;

onMounted(async () => {});

const onClick = () => {
  emits('onCardClick');
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "total-card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (onClick()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass(__props.icon),
          "aria-hidden": "true"
        }, null, 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(__props.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(__props.total), 1),
      _createVNode(_component_Button, { class: "p-button-only-icon" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "icon-chevron_big_right" }, null, -1)
        ])),
        _: 1
      })
    ])
  ]))
}
}

})