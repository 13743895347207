import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid--totals" }

import { PatientResume } from '@/models/Patient';
import router from '@/router';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { onMounted, ref, watch } from 'vue';
import TotalsCard from './TotalsCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TotalsSummary',
  props: {
  professionalId: {
    type: String,
  },
},
  setup(__props) {

const props = __props;

watch(
  () => props.professionalId,
  () => loadResume(),
);

const resume = ref<PatientResume>();

onMounted(async () => {
  await loadResume();
});

async function loadResume() {
  const result = await patientService.getPatientResume(props.professionalId);
  if (!(result instanceof ServiceError)) {
    resume.value = result;
  }
}

const navigateToPatient = (filter: string) => {
  router.push('/patients?filter=' + filter);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TotalsCard, {
      title: _ctx.$t('dashboard.activePatients'),
      total: resume.value?.active,
      icon: "icon-user_check",
      onOnCardClick: _cache[0] || (_cache[0] = ($event: any) => (navigateToPatient('active')))
    }, null, 8, ["title", "total"]),
    _createVNode(TotalsCard, {
      title: _ctx.$t('dashboard.medicalTestInProgressPatients'),
      total: resume.value?.inMedicalTestInProgress,
      icon: "icon-bar_chart_square",
      onOnCardClick: _cache[1] || (_cache[1] = ($event: any) => (navigateToPatient('medicalTestInProgress')))
    }, null, 8, ["title", "total"]),
    _createVNode(TotalsCard, {
      title: _ctx.$t('dashboard.wereablesPatients'),
      total: resume.value?.wearables,
      icon: "icon-smartwatch",
      onOnCardClick: _cache[2] || (_cache[2] = ($event: any) => (navigateToPatient('wereables')))
    }, null, 8, ["title", "total"])
  ]))
}
}

})